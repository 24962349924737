// dependecies
import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Box, makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
import { isEmpty, isArray } from 'lodash'
// components
import StrapiButton from './Button'

const useStyles = makeStyles(theme => ({
  buttonList: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  singleButton: ({ width = 100 }) => ({
    width: width === 100 ? '100%' : `calc(${width}% - 20px)`,
    '@media (max-width: 1024px)': {
      width: width === 100 ? '100%' : 'calc(50% - 20px)',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  }),
}))

const StrapiButtonList = ({ data: { ButtonList = {} } }) => {
  const buttons = isArray(ButtonList?.Buttons) && !isEmpty(ButtonList?.Buttons) ? ButtonList?.Buttons : null
  const classes = useStyles({ width: Math.floor(100 / (buttons?.length || 1)) })
  return (
    <Grid item md={12} data-testid={ButtonList?.id}>
      {ButtonList?.Heading && (
        <Typography className={classes.heading} variant="h4">
          {ButtonList.Heading}
        </Typography>
      )}
      <Box className={classes.buttonList}>
        {buttons &&
          buttons.map(({ Button }) => <StrapiButton key={Button?.id} data={Button} className={classes.singleButton} />)}
      </Box>
    </Grid>
  )
}

StrapiButtonList.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StrapiButtonList

export const StrapiButtonListFragent = graphql`
  fragment StrapiButtonListFragent on StrapiButtonList {
    id
    Title
    Heading
    Orientation
    Buttons {
      Button {
        ...StrapiButtonFragment
      }
    }
  }
`
