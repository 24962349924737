// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Card, CardActionArea, CardMedia, CardContent, Typography, makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
// hooks
import useHoverElevation from '@hooks/useHoverElevation'
// components
import SaleFlag from '@shared/sale-flag'
import { getLinkProps } from './Link'
import StrapiImage from './Image'

const useStyles = makeStyles(theme => ({
  card: {
    cursor: 'pointer',
    borderRadius: '0px',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      transform: ' scale(1.01)',
    },
    '& *:focus': {
      outline: 'none !important',
    },
    '& span[role=button] div[data-testid=saleFlag]': {
      top: 0,
      left: 0,
    },
  },
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  media: ({ isCollapsed, isCollapsedImageTop }) => {
    if (isCollapsed) {
      return {
        width: '100%',
        height: '5.6rem',
        objectFit: 'contain',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    }
    if (isCollapsedImageTop) {
      return { width: '100%', height: '5rem', objectFit: 'contain' }
    }
    return {
      height: 'auto',
      maxWidth: 650,
      maxHeight: 650,
      margin: '0 auto',
      width: '100%',
      objectFit: 'cover',
    }
  },
  cardName: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontWeight: 600,
    padding: theme.spacing(1),
    lineHeight: 1,
  },
  collapsed: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.875rem',
  },
  collapsedTop: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px',
  },
  imageWrapper: ({ isCollapsed, isCollapsedImageTop }) => {
    if (isCollapsed) {
      return { width: '50%' }
    }
    if (isCollapsedImageTop) {
      return { width: '45%' }
    }
    return {}
  },
  content: ({ isCollapsed }) => {
    if (isCollapsed) {
      return { width: '50%', padding: 0 }
    }
    return { width: '100%', padding: 0 }
  },
}))

const StrapiCategoryTile = ({ data }) => {
  const { onMouseEnter, onMouseLeave, elevation } = useHoverElevation()

  const link = data?.Link?.Link
  const buttonProps = link ? getLinkProps(data, link, { category: 'category tile' }) : {}

  const isCollapsed = Boolean(data?.Collapsed)
  const isCollapsedImageTop = Boolean(data?.CollapsedImageTop)
  const classes = useStyles({ isCollapsed, isCollapsedImageTop })
  const mediaProps = isCollapsed ? { height: 85 } : {}
  return (
    <Card
      data-testid={data?.id}
      className={classes.card}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      elevation={elevation}
    >
      <CardActionArea
        className={classNames(classes.link, {
          [classes.collapsed]: isCollapsed,
          [classes.collapsedTop]: isCollapsedImageTop,
        })}
        {...buttonProps}
      >
        <SaleFlag sale={data?.OnSale} />
        {data?.Image?.url && (
          <CardMedia
            component={StrapiImage}
            className={classes.media}
            wrapperClassName={classes.imageWrapper}
            data={{
              desktop: data?.Image,
              alt: data?.Name,
            }}
            title={data?.Title}
            {...mediaProps}
          />
        )}
        {data?.Name && (
          <CardContent className={classes.content}>
            <Typography className={classes.cardName} color="textPrimary" variant="body1" component="h4">
              {data.Name}
            </Typography>
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  )
}

StrapiCategoryTile.propTypes = {
  data: PropTypes.object.isRequired,
}

export const StrapiCategoryTileFragment = graphql`
  fragment StrapiCategoryTileFragment on StrapiCategoryTile {
    id
    __typename
    Collapsed
    CollapsedImageTop
    Title
    Name
    OnSale
    Link {
      Link {
        ...StrapiLinkFragment
      }
    }
    Image {
      ...StrapiImageFragment
    }
  }
`

export default StrapiCategoryTile
