// dependencies
import React, { useMemo, useCallback } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _split from 'lodash/split'
import _get from 'lodash/get'
import _map from 'lodash/map'
// context
import { useStrapiContext } from '@context/strapiContext'
// components
import ProductTile from '@components/product/product-tile'
import Coupon from './Coupon'
import CategoryTitle from './CategoryTile'
import CollectionTile from './CollectionTile'
import Markdown from './Markdown'

const SPACING = 3

const useStyles = makeStyles(t => ({
  gridContainer: {
    // ? Removing extra padding added by M-UI spacing
    width: `calc(100% + ${t.spacing(SPACING)}px)`,
    maxWidth: `calc(100% + ${t.spacing(SPACING)}px)`,
    marginLeft: `-${Math.floor(t.spacing(SPACING) / 2)}px`,
    marginBottom: `0px`,
  },
}))

const StrapiPageGrid = ({ data }) => {
  const grid = data?.Grid || {}
  const md = Math.floor(12 / (grid?.Columns || 4))
  const xs = Math.floor(12 / (grid?.MobileColumn || 1))

  const classes = useStyles()
  const { disabledCoupons } = useStrapiContext()

  const isCouponDisabled = useCallback(
    coupon => {
      const coupons = disabledCoupons?.data
      if (_isArray(coupons) && !_isEmpty(coupons)) {
        return coupons.some(
          id => id === (_get(coupon, 'id', []).includes('coupon:') ? _get(_split(coupon?.id, ':'), '1') : coupon?.id),
        )
      }
      return false
    },
    [disabledCoupons],
  )

  const content = useMemo(
    () =>
      grid?.Content?.filter(item => {
        if (item?.Coupon && isCouponDisabled(item?.Coupon)) {
          return false
        }
        return true
      }),
    [grid, isCouponDisabled],
  )
  if (content && Array.isArray(content)) {
    return (
      <Grid
        container
        spacing={SPACING}
        item
        md={12}
        className={classNames(classes.gridContainer, 'grid-wrapper')}
        data-testid={grid?.id}
      >
        {_map(content, (item, index) => {
          const key = `${grid?.id}-${item.__typename}-${index}`.toLowerCase()
          const View = {
            BaseCoupon: item?.Coupon && <Coupon data={item?.Coupon} />,
            BaseCategoryTile: item?.CategoryTile && <CategoryTitle data={item?.CategoryTile} />,
            BaseCollectionTile: item?.CollectionTile && <CollectionTile data={item?.CollectionTile} />,
            BaseProductTile: item?.SKU && (
              <ProductTile
                index={index}
                id={`product-title:${item?.SKU}`}
                sku={item?.SKU}
                source="strapi-grid"
                antiFlickering
                showSwatches={false}
              />
            ),
            PageMarkdown: item?.Markdown && <Markdown data={item?.Markdown} />,
          }
          return (
            <Grid item md={md} xs={xs} key={key}>
              {View[item.__typename] || null}
            </Grid>
          )
        })}
      </Grid>
    )
  }

  return null
}

StrapiPageGrid.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StrapiPageGrid

export const StrapiPageGridFragment = graphql`
  fragment StrapiPageGridFragment on StrapiGrid {
    id
    Columns
    MobileColumn
    Content {
      __typename
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on BaseCoupon {
        Coupon {
          ...StrapiCouponFragment
        }
      }
      ... on BaseCategoryTile {
        CategoryTile {
          ...StrapiCategoryTileFragment
        }
      }
      ... on BaseCollectionTile {
        CollectionTile {
          ...StrapiCollectionTileFragment
        }
      }
      ... on BaseProductTile {
        __typename
        SKU
      }
    }
  }
`
