// dependencies
import React from 'react'
// components
import ProductTile from '@components/product/product-tile'
import Coupon from '../Coupon'
import CategoryTitle from '../CategoryTile'
import CollectionTile from '../CollectionTile'
import StrapiButton from '../Button'
import StrapiMarkdown from '../Markdown'

const generateItemKey = (Slider, item) => (typeName = '') =>
  `${Slider?.id}-${item.__typename}-${item?.[typeName]?.id || item?.[typeName]}`.toLowerCase()

const getSlides = Slider => {
  const items = Slider?.Slides || []
  return items
    .map((item, index) => {
      const getKey = generateItemKey(Slider, item)
      const View = {
        BaseCoupon: item?.Coupon && <Coupon data={item?.Coupon} key={getKey('Coupon')} />,
        BaseCategoryTile: item?.CategoryTile && (
          <CategoryTitle data={item?.CategoryTile} key={getKey('CategoryTile')} />
        ),
        BaseCollectionTile: item?.CollectionTile && (
          <CollectionTile data={item?.CollectionTile} key={getKey('CollectionTile')} />
        ),
        BaseProductTile: item?.SKU && (
          <ProductTile
            index={index}
            id={`product-title:${item?.SKU}`}
            sku={item?.SKU}
            source="strapi-slider"
            antiFlickering
            showSwatches={false}
            key={getKey('SKU')}
          />
        ),
        BaseButton: item?.Button && <StrapiButton key={getKey('Button')} data={item?.Button} />,
        PageMarkdown: item?.Markdown && (
          <StrapiMarkdown key={getKey('Markdown')} data={item} gridWrapper={false} Alignment="center" />
        ),
      }
      return View[item.__typename] || null
    })
    .filter(component => Boolean(component))
}

export default getSlides
