// dependecies
import React, { useState, useCallback, useMemo } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import SlickSlider from '@rtgdev/react-slick'
import { makeStyles, Typography, Grid } from '@material-ui/core'
// styles
import '@comp-sass/shared/slider/slick.scss'
import '@comp-sass/shared/slider/slick-theme.scss'
// helpers
import { getWindowSizeSettings } from '@helpers/slider'
import useWindowResize from '@helpers/useWindowResize'
import getSlides from './getSlides'
// components
import SliderArrow from './SliderArrow'
import StrapiLink from '../Link'

const useStyles = makeStyles(theme => ({
  heading: {
    textAlign: 'center',
    position: 'relative',
    margin: '20px 0',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '8px',
    },
  },
  linkWrapper: {
    position: 'absolute',
    top: '2rem',
    right: '6rem',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      position: 'unset',
      textAlign: 'center',
    },
  },
  link: {
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  headingTitle: {
    fontWeight: 300,
  },
}))

const innerWidth = typeof window !== 'undefined' ? window.innerWidth : null

const StrapiSlider = ({ data, children = null, sliderProps = {} }) => {
  const Slider = data?.Slider || data
  const className = Slider?.Size === 'Small' ? 'strapi__small-slider' : ''
  const classes = useStyles()
  const [windowWidth, setWindowWidth] = useState(innerWidth)
  const slides = useMemo(() => children || getSlides(Slider), [Slider, children])

  const checkWindowWidth = useCallback(() => {
    if (typeof window !== 'undefined' && window.innerWidth !== windowWidth) {
      setWindowWidth(window.innerWidth)
    }
  }, [windowWidth])

  useWindowResize(checkWindowWidth, innerWidth)

  const settings = getWindowSizeSettings({
    ...sliderProps,
    contentComponents: slides,
    nextArrow: <SliderArrow variant="next" heading={Slider?.Heading} />,
    prevArrow: <SliderArrow variant="previous" heading={Slider?.Heading} />,
    initialSlide: 0,
    infinite: false,
    windowWidth,
    sliderTitle: Slider?.Title || Slider?.Heading,
  })

  return (
    <Grid item md={12} className={className} data-testid={Slider?.id}>
      {(Slider?.Heading || Slider?.ViewAllLink?.Link) && (
        <div className={classes.heading}>
          {Slider?.Heading && (
            <Typography color="textPrimary" className={classes.headingTitle} variant="h5">
              {Slider.Heading.toUpperCase()}
            </Typography>
          )}
          {Slider?.ViewAllLink?.Link && (
            <div className={classes.linkWrapper}>
              <StrapiLink data={Slider?.ViewAllLink?.Link} arrowNext className={classes.link} />
            </div>
          )}
        </div>
      )}
      <SlickSlider {...settings}>{slides}</SlickSlider>
    </Grid>
  )
}

StrapiSlider.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.element,
  sliderProps: PropTypes.object,
}

export default StrapiSlider

export const StrapiSliderFragment = graphql`
  fragment StrapiSliderfragment on StrapiSlider {
    id
    Title
    Heading
    DisplayHeader
    Size
    ViewAllLink {
      Link {
        ...StrapiLinkFragment
      }
    }
    Slides {
      __typename
      ... on BaseProductTile {
        SKU
      }
      ... on BaseCollectionTile {
        CollectionTile {
          ...StrapiCollectionTileFragment
        }
      }
      ... on BaseCategoryTile {
        CategoryTile {
          ...StrapiCategoryTileFragment
        }
      }
      ... on BaseCoupon {
        Coupon {
          ...StrapiCouponFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on BaseButton {
        Button {
          ...StrapiButtonFragment
        }
      }
    }
  }
`
