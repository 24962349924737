import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// https://react-slick.neostack.com/docs/example/custom-arrows
const SliderArrow = ({ variant, heading, onClick, className, style }) => (
  <button
    type="button"
    data-role="none"
    gtm-category="slider"
    gtm-action="slider scroll"
    gtm-label={heading}
    aria-label={variant}
    onClick={onClick}
    className={classNames(className, 'strapi-slider-arrow')}
    style={style}
  >
    <svg
      role="presentation"
      aria-hidden="true"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      preserveAspectRatio="xMinYMin"
    >
      {variant === 'next' ? (
        <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
      ) : (
        <path d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z" />
      )}
    </svg>
  </button>
)

SliderArrow.propTypes = {
  variant: PropTypes.oneOf(['previous', 'next']).isRequired,
  heading: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default SliderArrow
