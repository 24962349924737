// dependecies
import React, { useMemo, useState, useCallback, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Paper, ButtonBase, makeStyles, Typography } from '@material-ui/core'
import { isArray, isEmpty } from 'lodash'
// context
import { useStrapiContext } from '@context/strapiContext'
// hooks
import useHoverElevation from '@hooks/useHoverElevation'
// components
import StrapiButton from './Button'
import { getLinkProps } from './Link'

const useStyles = makeStyles(theme => ({
  wrapper: ({ disclaimer }) => ({
    border: '2px dashed #dedede',
    cursor: 'pointer',
    borderRadius: '0px',
    position: 'relative',
    /* if a disclaimer is included, increase the available space by 50px */
    paddingBottom: disclaimer ? '100px' : '50px',
    height: '100%',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      transform: ' scale(1.01)',
    },
    '& *:focus': {
      outline: 'none !important',
    },
  }),
  disclaimer: {
    fontStyle: 'italic',
    display: 'block',
    padding: theme.spacing(1),
    textAlign: 'center',
    bottom: 50,
    position: 'absolute',
    width: '100%',
  },
  link: {
    display: 'block',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  couponBtn: {
    bottom: 0,
    position: 'absolute',
    width: '100%',
  },
  content: ({ backgroundImage, alignment }) => ({
    padding: theme.spacing(1),
    textAlign: alignment,
    minHeight: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    ...(backgroundImage?.url
      ? {
          backgroundImage: backgroundImage?.url ? `url(${backgroundImage.url})` : null,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      : {}),
  }),
}))

const StrapiCoupon = ({ data }) => {
  const { onMouseEnter, onMouseLeave, elevation } = useHoverElevation()
  const { disabledCoupons } = useStrapiContext()

  const classes = useStyles({
    backgroundImage: data?.BackgroundImage,
    disclaimer: data?.Disclaimer,
    alignment: data?.ContentAlignment,
  })
  const __html = data?.Content?.childMarkdownRemark?.html
  const link = data?.Button?.Button?.Link?.Link
  const buttonProps = link ? getLinkProps(data, link, { category: 'coupon' }) : {}

  const isDisabled = useMemo(() => {
    const coupons = disabledCoupons?.data
    if (isArray(coupons) && !isEmpty(coupons)) {
      return coupons.some(({ id }) => id === data?.id)
    }
    return false
  }, [disabledCoupons, data])

  if (isDisabled) {
    return null
  }

  return (
    <Paper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      elevation={elevation}
      className={classes.wrapper}
      data-testid={data?.id}
    >
      {link ? (
        <ButtonBase {...buttonProps} className={classes.link}>
          <div className={classes.content} dangerouslySetInnerHTML={{ __html }} />
        </ButtonBase>
      ) : (
        <div className={classes.content} dangerouslySetInnerHTML={{ __html }} />
      )}
      {data?.Disclaimer && (
        <Typography className={classes.disclaimer} variant="body2">
          {data.Disclaimer}
        </Typography>
      )}
      <StrapiButton data={data?.Button?.Button} trackingData={buttonProps.trackingData} className={classes.couponBtn} />
    </Paper>
  )
}

StrapiCoupon.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StrapiCoupon

export const StrapiCouponFragment = graphql`
  fragment StrapiCouponFragment on StrapiCoupon {
    id
    Disabled
    Disclaimer
    Title
    ContentAlignment
    BackgroundImageAltText
    BackgroundImage {
      ...StrapiImageFragment
    }
    Button {
      Button {
        ...StrapiButtonFragment
      }
    }
    Content {
      childMarkdownRemark {
        id
        html
      }
    }
  }
`
