// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardActionArea, CardMedia, CardContent, Typography, makeStyles } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { graphql } from 'gatsby'
// hooks
import useHoverElevation from '@hooks/useHoverElevation'
// components
import StrapiImage from './Image'
import { getLinkProps } from './Link'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    cursor: 'pointer',
    borderRadius: '0px',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      transform: ' scale(1.01)',
    },
    '& *:focus': {
      outline: 'none !important',
    },
    '& span[role=button] div[data-testid=saleFlag]': {
      top: 0,
      left: 0,
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  media: {
    height: 'auto',
    maxWidth: 650,
    maxHeight: 650,
    margin: '0 auto',
    width: '100%',
    objectFit: 'cover',
  },
  content: {
    textAlign: 'center',
    minHeight: 260,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  callToAction: {
    display: 'flex',
    fontWeight: 600,
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    fontWeight: 600,
  },
  icons: {
    position: 'relative',
    display: 'inline-block',
    width: 24,
    height: 24,
    '& svg': {
      position: 'absolute',
      top: 0,
      fontSize: '22px',
    },
    '& svg:first-child': {
      left: 0,
    },
    '& svg:nth-child(2)': {
      left: 8,
    },
  },
}))

const Icons = props => (
  <span {...props}>
    <NavigateNextIcon />
    <NavigateNextIcon />
  </span>
)

const StrapiCollectionTile = ({ data }) => {
  const classes = useStyles()
  const { onMouseEnter, onMouseLeave, elevation } = useHoverElevation()

  const link = data?.Link?.Link
  const buttonProps = link ? getLinkProps(data, link, { category: 'collection tile' }) : {}

  return (
    <Card
      data-testid={data?.id}
      className={classes.card}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      elevation={elevation}
    >
      <CardActionArea className={classes.link} {...buttonProps}>
        {data?.Image?.url && (
          <CardMedia
            component={StrapiImage}
            className={classes.media}
            data={{
              desktop: data?.Image,
              alt: data?.Heading,
            }}
            title={data?.Title}
          />
        )}
        <CardContent className={classes.content}>
          <div>
            <Typography className={classes.heading} gutterBottom variant="h6" component="h4">
              {data?.Heading || ''}
            </Typography>
            <Typography gutterBottom variant="body1">
              {data?.Description || ''}
            </Typography>
          </div>
          <Typography className={classes.callToAction} variant="body1">
            VIEW THE COLLECTION &nbsp;
            <Icons className={classes.icons} />
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

StrapiCollectionTile.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StrapiCollectionTile

export const StrapiCollectionTileFragment = graphql`
  fragment StrapiCollectionTileFragment on StrapiCollectionTile {
    id
    Description
    Heading
    Title
    ImageAltText
    Image {
      ...StrapiImageFragment
    }
    Link {
      Link {
        ...StrapiLinkFragment
      }
    }
  }
`
