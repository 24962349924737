import React from 'react'
import { objectOf, any } from 'prop-types'
import PageButtonList from '@templates/strapi-cms/content-types/ButtonList'
import PageSlider from '@templates/strapi-cms/content-types/Slider'
import PageBanner from '@templates/strapi-cms/content-types/Banner'
import PageHtml from '@templates/strapi-cms/content-types/HTML'
import PageGrid from '@templates/strapi-cms/content-types/Grid'
import PageMarkdown from '@templates/strapi-cms/content-types/Markdown'
import { Grid } from '@material-ui/core'
import { graphql } from 'gatsby'
// helpers
import { getTypeKey } from '@helpers/strapi'

export const StrapiMappings = {
  PageBanner,
  PageMarkdown,
  PageButtonList,
  PageGrid,
  PageSlider,
  PageHtml,
}

const CreateContent = (contentGroup, index) => {
  if (contentGroup) {
    const { id, Content } = contentGroup
    return (
      <Grid container item md={12} className="page-content-group" key={id} data-testid={id}>
        {Content &&
          Array.isArray(Content) &&
          Content.map((subContent, subIndex) => {
            const Component = StrapiMappings[subContent.__typename]
            return Component ? (
              <Component
                data={subContent}
                contentIndex={`${index}:${subIndex}`}
                key={getTypeKey(subContent, 'content-group-')}
              />
            ) : null
          })}
      </Grid>
    )
  }
  return null
}

export function ContentGroup({ data, index = 0 }) {
  const contentGroup = data?.strapiContentGroup ?? data?.ContentGroup ?? data
  return contentGroup && CreateContent(contentGroup, index)
}

ContentGroup.propTypes = {
  data: objectOf(any).isRequired,
}

export const StrapiContentGroupFragment = graphql`
  fragment StrapiContentGroupFragment on StrapiContentGroup {
    id
    Content {
      __typename
      ... on PageGrid {
        Grid {
          ...StrapiPageGridFragment
        }
      }
      ... on PageBanner {
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      ... on PageSlider {
        Slider {
          ...StrapiSliderfragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on PageButtonList {
        ButtonList {
          ...StrapiButtonListFragent
        }
      }
      ... on PageHtml {
        HTML {
          ...StrapiHtmlFragment
        }
      }
    }
  }
`

export default ContentGroup
